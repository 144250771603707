import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  selectLocation,
  selectLocationReport
} from 'reports/production/selectors'
import { reportShape } from 'reports/production/shapes'
import { Characters } from 'lib/const'
import ReportGraph from 'components/ReportGraph'

const ProductionReportSection = ({ id, report, location, children }) => {
  const [realized, planned] = report
  let percentage

  if (planned === 0 && realized > 0) {
    percentage = 100.0
  } else if (planned === 0 && realized === 0) {
    percentage = 0.0
  } else {
    percentage = Math.round((1000 * realized) / planned) / 10.0
  }

  return (
    <div className='report-section'>
      <div className='report-section__data'>
        <div className='report-section__title'>
          <h1>{location.name}</h1>
          <h1>{isNaN(percentage) ? '---' : percentage.toFixed(1) + '%'}</h1>
        </div>
        <div className='report-section__graphs'>
          <ReportGraph
            values={report}
            character={Characters.PRODUCTION}
            small
            showLabel={false}
          />
        </div>
      </div>

      <div className='report-section__locations'>{children}</div>
    </div>
  )
}

ProductionReportSection.propTypes = {
  id: PropTypes.number.isRequired,
  children: PropTypes.any,
  report: reportShape,
  location: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
}

const mapStateToProps = (state, { id }) => ({
  report: selectLocationReport(state, id),
  location: selectLocation(state, id)
})

export default connect(mapStateToProps)(ProductionReportSection)
